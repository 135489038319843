
const { Base64 } = require( 'skylar/Util' );

export class SkylarAPI {

	async request( path, body ) {
		const response = await fetch( path, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify( body )
		});

		return response.json();
	}

	async recognize( audiofile ) {
		const file = await Base64.fromBlob( audiofile );
		const { text } = await this.request( '/api/recognize', { file } );
		return text;
	}

	async userConversation( message, reset_subject ) {

		const body = {
			message,
			reset_subject
		};

		return await this.request( '/api/user-conversation', body );
	}

	async speech( text ) {
		const body = { text };
		let audiodata = null;
		const { audio_base64, messagedata } = await this.request( '/api/speech', body );

		if ( audio_base64 ) {
			const audioData = Base64.parse( audio_base64 );
			audiodata = new Blob( [audioData], { type: 'audio/mpeg' });
		}

		return { audiodata, messagedata };
	}

}
