export class SpeakerVisualizer {

	analyzer;
	source;
	stream;
	element;
	speaking;
	
	constructor( element, stream ) {
		this.element = element;

		if ( stream ) {
			this.setSource( stream );
		}
	
		this.animate();
		this.setSpeaking( false );
	}

	setSource( stream ) {
		this.stream = stream;
		this.audioContext = new AudioContext();
		this.analyzer = this.audioContext.createAnalyser();
		this.analyzer.fftSize = 32;
		this.source = this.audioContext.createMediaStreamSource( stream );
		this.source.connect( this.analyzer );
	}

	setSpeaking( speaking ) {
		this.speaking = speaking;

		if ( !speaking ) {
			this.setLevel( 0 );
		}
	}

	animate() {
		requestAnimationFrame( () => this.animate() );

		if ( !this.speaking ) {
			return;
		}

		if ( ! this.stream ) {
			return;
		}
	
		const val = this.getVolume();
		this.setLevel( val );
	}

	setLevel( val ) {
		const px = Math.floor( val ) + 'px';

		if ( this.element ) {
			this.element.style.boxShadow = `0 0 ${px} blue`;
		}
	}

	getVolume() {
		const arr = new Uint8Array( this.analyzer.frequencyBinCount );
		this.analyzer.getByteFrequencyData( arr );
		return this.getAverage( arr );
	}

	getAverage( data ) {
		let values = 0;
		const length = data.length;
		for ( let i = 0; i<length; i++ ) {
			values += data[i];
		}

		return values / length;
	}


}

export default SpeakerVisualizer;