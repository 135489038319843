export class AgentVizualizer {

	analyzer;
	source;
	stream;
	canvas;
	context;
	speaking;

	thinking_timer;
	thinking_counter = 0;
	thinking_phrases = [
		'Gathering my thoughts',
		'Considering what you\'ve shared',
		'Almost ready',
		'Just wrapping up'
	];

	constructor( video, body ) {
		this.video = video;
		this.body = body;
		this.speaking = false;

		this.animate();
		this.setSpeaking( false );
		this.setThinking( false );
	}

	setSource( stream ) {
		this.audioContext = new AudioContext();
		this.source = this.audioContext.createMediaElementSource( stream );
		this.source.connect( this.audioContext.destination );
		this.analyzer = this.audioContext.createAnalyser();
		this.analyzer.fftSize = 32;
		this.analyzer.connect( this.audioContext.destination );
		this.source.connect( this.analyzer );

	}

	setSpeed( num ) {
		if ( this.video ) {
			this.video.playbackRate = num;
		}
	}

	setSpeaking( is_speaking ) {
		this.speaking = is_speaking;

		if ( is_speaking ) {
			this.setSpeed( 2 );
		} else {
			this.setSpeed( .5 );
			this.setScale( 1 );
		}
	}

	setThinking( is_thinking ) {
		this.thinking = is_thinking;

		if ( this.body ) {
			this.body.classList.toggle( 'thinking', is_thinking );
		}

		if ( is_thinking && ! this.thinking_timer ) {
			this.thinking_counter = 0;
			this.thinkingPhrase();
		}

	}

	thinkingPhrase() {
		if ( ! this.thinking ) {
			this.thinking_timer = null;
			return;
		}
	
		const indicator = document.getElementById( 'thinking_indicator' );
		if ( indicator ) {
			indicator.textContent = this.thinking_phrases[this.thinking_counter];
		}
	
		this.thinking_counter++;
		if ( this.thinking_counter >= this.thinking_phrases.length ) {
			this.thinking_counter--;
		}

		this.thinking_timer = setTimeout( this.thinkingPhrase.bind(this), 5000 );
	}

	animate() {
		requestAnimationFrame( () => this.animate() );
		if ( !this.speaking ) {
			return;
		}

		if ( !this.video ) {
			return;
		}
	
		const val = this.getVolume() / 255;
		const h = 1 + ( val * .5 );

		this.setScale( h );
	}

	setScale( scale ) {
		if ( this.video ) {
			this.video.style.scale = scale;
		}
	}

	getVolume() {
		const arr = new Uint8Array( this.analyzer.frequencyBinCount );
		this.analyzer.getByteFrequencyData( arr );
		return this.getAverage( arr );
	}

	getAverage( data ) {
		let values = 0;
		const length = data.length;
		for ( let i = 0; i<length; i++ ) {
			values += data[i];
		}

		return values / length;
	}


}

export default AgentVizualizer;