export class AudioCapture {

	mimeType = 'audio/webm';
	stream;

	constructor( config ) {
		this.stream = null;
		this.mediaRecorder = null;
		this.audioChunks = [];

		if ( config.audioinit || config.interview ) {
			this.configureAudio();
		}
	}

	configureAudio() {
		this.startMic()
			.catch( error => console.log( error ) );

	}

	async startMic() {
		await this.media_stream();
		if ( this.initMicButton ) {
			this.initMicButton.style.display = this.stream ? 'none' : '';
		}
	}

	async media_stream() {
		try {
			this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		} catch (error) {
			console.log( 'Problem gaining access to mic.', error );
		}

	}

	async start() {

		this.audioChunks = [];

		try {
			this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: this.mimeType } );
			this.mediaRecorder.ondataavailable = (event) => {
				event.data.size && this.audioChunks.push(event.data);
			};

			this.mediaRecorder.start();
		} catch (error) {
			console.error('Error starting audio capture:', error);
			throw error;
		}
	}

	stop( stopCallback ) {
		if (this.mediaRecorder) {
			if ( stopCallback ) {
				this.mediaRecorder.onstop = async () => {
					const audioBlob = await this.getAudioBlob();
					stopCallback( audioBlob );
				};
			}
			this.mediaRecorder.stop();
			this.stream.getTracks().forEach(track => track.stop());
		}
	}

	async getAudioBlob() {
		return new Blob( this.audioChunks, { type: this.mimeType } );
	}

}